import {
  trigger,
  state,
  style,
  animate,
  transition,
  sequence,
  query,
  // ...
} from '@angular/animations';

export const preventChildAnimations = trigger('preventChildAnimations',[ transition('* => *', [])])

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('500ms', style({opacity: 1}))
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate('200ms', style({opacity: 0}))
  ])
])

export const blindTrigger = trigger('fadeOut', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-30vh) scale(0.5)',
      height: '0px',
      overflow: 'visible'
    }),
    animate('300ms ease-in-out', style({
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      height: '*'
    })),
  ]),
  transition(':leave', [
    animate('1s ease-in-out',
      style({
        opacity: 0,
        transform: 'translateY(-30vh) scale(0.5)',
        height: '0px',
        overflow: 'visible'
      })
    )
  ])
]);

export const routeAnimations = trigger('routeAnimations', [
  transition('* => Overlay', [
    style({ 
      // position: 'relative', 
      // // width: '100%',
      // // height: '100vh',
      // // overflow: 'hidden' 
    }),
    query(':enter', [
      style({
        position: 'fixed',
        transform: 'translateY(100%)',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        overflow: 'hidden'
      }),
      animate('300ms cubic-bezier(.34,.9,.47,1)',
        style({ transform: 'translateY(0)' })
      )
    ]),
    query(':leave', [
      style({ 
        position:'relative',
        overflow: 'hidden'
      })
    ], { optional: true },),
  ]),
  transition('Overlay => *', [
    query(':leave', [
      style({
        transform: 'translateY(0)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        overflow: 'hidden'
      }),
      animate('300ms cubic-bezier(.54,0,.82,.65)',
        style({ transform: 'translateY(100%)' }))
    ])
  ]),
])

export const roundsTrigger = trigger('roundsAnimation', [
  transition(':enter', sequence([
    style({
      transform: 'translateX(-100px)',
      opacity: 0,
      overflow: 'hidden',
      height: '0px'
    }),
    animate('200ms cubic-bezier(.34,.9,.47,1)',
      style({ height: '*' })),
    animate('200ms cubic-bezier(.34,.9,.47,1)',
      style({
        transform: 'translateX(0)',
        opacity: 1
      }))
  ])),
  transition(':leave', sequence([
    style({
      transform: 'translateX(0)',
      opacity: 1,
      overflow: 'hidden',
      height: '*'
    }),
    animate('150ms cubic-bezier(.34,.9,.47,1)',
      style({
        transform: 'translateX(-50px)',
        opacity: 0
      })),
    animate('150ms cubic-bezier(.34,.9,.47,1)',
      style({ height: '0px' }))
  ])),
])