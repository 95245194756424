import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { HighNumberPipe } from './high-number.pipe';
import { PositionBelowDirective } from './directives/position-below.directive';
import { SwitchComponent } from './switch/switch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimePipe } from './time.pipe';
import { RepeatPipe } from './repeat.pipe';



@NgModule({
  declarations: [ChipComponent, HighNumberPipe, PositionBelowDirective, SwitchComponent, TimePipe, RepeatPipe],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [ChipComponent, HighNumberPipe, PositionBelowDirective, SwitchComponent, TimePipe, RepeatPipe]
})
export class SharedComponentsModule { }
