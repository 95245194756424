import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'pt-progress-graphic',
  templateUrl: './progress-graphic.component.html',
  styleUrls: ['./progress-graphic.component.scss'],
})
export class ProgressGraphicComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('mainSvg', { static: false }) svg: ElementRef<SVGElement>;
  @ViewChild('basePath', { static: false })
  basePath: ElementRef<SVGPathElement>;
  @ViewChildren('path') allPaths: QueryList<ElementRef<SVGPathElement>>;

  @Input() padding: string | number = 50;
  @Input() timeLeft = 20;
  @Input() totalTime: number = 60 * 10;
  @Input() isPlaying = true;
  @Input() updatePeriod = 100; // ms

  
  length = 0;
  progress = 0;

  hostEl: HTMLElement;
  animate = true;

  private debounceTimeout = setTimeout(() => {}, 0);

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.hostEl = this.elRef.nativeElement;
  }

  @HostListener('window:orientationchange', ['$event'])
  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.animate = false;
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(() => {
      this.animate = true;
    }, 100);
    this.setPathPoints();
    this.setPathLength();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeLeft || changes.totalTime) {
      this.updateProgress();
    }
    if (changes.padding?.isFirstChange() === false) {
      this.setPathPoints();
      this.setPathLength();
    }
    if (changes.isPlaying?.isFirstChange() === false) {
      this.updateProgress();
    }
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setPathPoints();
      this.setPathLength();
      //this.startTimer()
    }, 0);
  }

  private setPathPoints() {
    const box = this.svg.nativeElement.getBoundingClientRect();
    const w = box.width;
    const h = box.height;
    const midHight = h / 2;
    const midWidth = w / 2;
    const padding = this.getPaddingValues(this.padding)
    const offsetTop = padding.top;
    const offsetBottom = h - padding.bottom;
    const offsetLeft = padding.left;
    const offsetRight = w - padding.right;

    // get half length of the short side, and use that to set the curve length on the long side
    const isPortrait = h > w;
    const h1 = isPortrait ? midWidth : h / 2;
    const h2 = isPortrait ? midWidth : w - h1;
    const v1 = isPortrait ? w / 2 : midHight;
    const v2 = isPortrait ? h - v1 : midHight;

    const d = `
      M ${midWidth} ${offsetTop}
      H ${h2}
      Q ${offsetRight} ${offsetTop}, ${offsetRight} ${v1} 
      V ${v2} 
      Q ${offsetRight} ${offsetBottom}, ${h2} ${offsetBottom} 
      H ${h1} 
      Q ${offsetLeft} ${offsetBottom}, ${offsetLeft} ${v2} 
      V ${v1} 
      Q ${offsetLeft} ${offsetTop}, ${h1} ${offsetTop} 
      Z`;
    this.allPaths.forEach((path) =>
      this.renderer.setAttribute(path.nativeElement, 'd', d)
    );
    this.renderer.setAttribute(
      this.svg.nativeElement,
      'viewBox',
      `0 0 ${w} ${h}`
    );
  }

  private setPathLength() {
    this.length = this.basePath.nativeElement.getTotalLength();
  }

  private updateProgress() {
    if (
      this.totalTime > 0 &&
      this.timeLeft >= 0 &&
      this.totalTime >= this.timeLeft
    ) {
      this.progress = Math.min(
        1,
        (this.totalTime - this.timeLeft + (this.isPlaying ? 1 : 0)) /
          this.totalTime
      );
    } else {
      this.progress = 0;
    }
  }

  private getPaddingValues(paddingString:string|number): {
    top: number;
    bottom: number;
    left: number;
    right: number;
  } {
    if (typeof paddingString === 'number') {
      return {
        top: paddingString,
        bottom:paddingString,
        left: paddingString,
        right: paddingString,
      };
    }
    const p = paddingString.split(' ');
    if (p.length === 1) {
      const p = +paddingString;
      return { top: p, bottom: p, left: p, right: p };
    }
    if (p.length === 2) {
      const y = +p[0]
      const x = +p[1]
      return { top: y, bottom: y, left: x, right:x };
    }
    if (p.length === 3) {
      const t = +p[0]
      const x = +p[1]
      const b = +p[2]
      return { top: t, bottom: b, left: x, right:x };
    }
    if (p.length === 4) {
      const t = +p[0]
      const r = +p[1]
      const b = +p[2]
      const l = +p[3]
      return { top: t, bottom: b, left: l, right:r };
    }
  }
}
