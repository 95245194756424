import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Round, RoundSettings } from '../../models/round.model';
import { v4 as uuid } from 'uuid';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { preventChildAnimations, roundsTrigger } from '../../core/animation.triggers';

const requiredWhenNotBreakValidator = (control: AbstractControl) =>
    control.parent?.parent?.get('break')?.value ? null : Validators.required(control);

export const createControlGroupFromRound = (round: Round) =>
    new FormGroup({
        id: new FormControl(round.id),
        time: new FormControl(round.time, Validators.required),
        break: new FormControl(round.break === true),
        breakMessage: new FormControl(round.breakMessage),
        blinds: new FormGroup({
            bigBlind: new FormControl(
                round.blinds?.bigBlind,
                requiredWhenNotBreakValidator,
            ),
            smallBlind: new FormControl(
                round.blinds?.smallBlind,
                requiredWhenNotBreakValidator,
            ),
        }),
    });

@Component({
    selector: 'pt-round-settings',
    templateUrl: './round-settings.component.html',
    styleUrls: ['./round-settings.component.scss'],
    animations: [roundsTrigger, preventChildAnimations],
})
export class RoundSettingsComponent implements OnInit, OnDestroy {
    @ViewChildren('timeInput') timeInputs: QueryList<ElementRef>;
    @Output() onChange = new EventEmitter<void>();

    destroyed$ = new Subject<void>();

    get formGroup() {
        return this.controlContainer.control as FormGroup;
    }
    get formRounds() {
        return this.formGroup.get('rounds') as FormArray;
    }
    get formRoundControls() {
        return this.formRounds.controls as FormGroup[];
    }

    get defaultRound(): Round {
        return {
            id: uuid(),
            time: this.formGroup.get('defaultRoundTime').value,
            break: false,
            blinds: { smallBlind: null, bigBlind: null },
        };
    }
    get defaultBreak(): Round {
        return {
            id: uuid(),
            time: this.formGroup.get('defaultBreakTime').value,
            break: true,
            blinds: null,
        };
    }

    get totalGameTime() {
        return (this.formRounds.value as Round[]).map(r => r.time).reduce((t,r)=> t+r,0)
    }
    constructor(public controlContainer: ControlContainer) {}

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    ngOnInit(): void {}

    tabOnBigBlind(index: number) {
        if (index === this.formRounds.length - 1) {
            this.addRound('blinds');
        }
    }

    addRoundPlaceholder(i: number) {
        // remove other placeholders
        this.formRounds.controls.forEach((control, index) => {
            if (!control.value.id) {
                this.formRounds.removeAt(index);
            }
        });

        const newRoundPlaceholder: Round = {
            id: null,
            time: 0,
        };

        const placeHolderRound = createControlGroupFromRound(newRoundPlaceholder);
        placeHolderRound.disable();
        this.formRounds.insert(i, placeHolderRound);
    }

    addBlindRound(i?: number) {
        this.addRound('blinds', i);
    }

    addBreakRound(i?: number) {
        this.addRound('break', i);
    }

    removeRound(i: number) {
        this.formRounds.removeAt(i);
        this.formGroup.markAsDirty();
    }
    
    onChangeSmallBlind(round: FormGroup) {
        const small = round.get('blinds.smallBlind').value;
        round.get('blinds.bigBlind').setValue(small * 2);
    }

    calculateNextSmallBlind(): number {
        return 25;
    }

    applyDefaultRound() {
        const defaultTime = this.formGroup.get('defaultRoundTime').value;
        this.formRounds.controls.forEach((c) => {
            if (!c.value.break) {
                c.get('time').setValue(defaultTime);
            }
        });
    }

    applyDefaultBreak() {
        const defaultTime = this.formGroup.get('defaultBreakTime').value;
        this.formRounds.controls.forEach((c) => {
            if (c.value.break) {
                c.get('time').setValue(defaultTime);
            }
        });
    }

    drop(event: CdkDragDrop<FormControl[]>) {
        const round = this.formRounds.at(event.previousIndex);
        this.formRounds.removeAt(event.previousIndex);
        this.formRounds.insert(event.currentIndex, round);
        this.formGroup.markAsDirty();
        this.onChange.emit();
    }

    focusAndSelect(event: MouseEvent): any {
        (event.target as HTMLInputElement).focus();
        (event.target as HTMLInputElement).select();
    }

    private addRound(type: 'blinds' | 'break', i?: number) {
        const newRound = type === 'blinds' ? this.defaultRound : this.defaultBreak;
        if (i != null) {
            this.formRounds.at(i).patchValue(newRound);
            this.formRounds.at(i).enable();
        } else {
            this.formRounds.push(createControlGroupFromRound(newRound));
            i = this.formRounds.length;
        }
        this.formGroup.markAsDirty();
        this.formGroup.updateValueAndValidity();

        setTimeout(() => {
            const timeEl = this.timeInputs?.get(i)?.nativeElement as HTMLInputElement;
            if (timeEl) {
                timeEl.dispatchEvent(new Event('click'));
            }
        }, 0);
    }
}
