import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';

const srcPath = '../../assets/';

interface SoundObj {
    audio: HTMLAudioElement;
    src: string;
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SoundsService {
    private playSounds: boolean;

    private allSounds = {
        secondWarning: {
            audio: new Audio(),
            src: 'second-warning.wav',
            loaded: false,
        },
        minuteWarning: {
            audio: new Audio(),
            src: 'minute-warning.wav',
            loaded: false,
        },
        newRound: {
            audio: new Audio(),
            src: 'new-round.wav',
            loaded: false,
        },
    };

    constructor(private settingsService: SettingsService) {
        this.settingsService.settings
            .pipe(map((settings) => settings.general.playSounds))
            .subscribe((play) => (this.playSounds = play));
        const clickEvent = (e: MouseEvent) => {
            document.removeEventListener('click', clickEvent);
            this.loadAndPlayAllSounds();
        };
        document.addEventListener('click', clickEvent);
    }

    playSecondsWarning() {
        this.playSound(this.allSounds.secondWarning);
    }
    playMinuteWarning() {
        this.playSound(this.allSounds.minuteWarning);
    }
    playNewRoundSound() {
        this.playSound(this.allSounds.newRound);
    }

    private playSound(sound: SoundObj) {
        if (!this.playSounds) return;
        if(!sound.loaded) console.warn(`sound didn't load:`, sound.src)
        sound.audio.play();
        console.log('sound attempt played', sound.src, new Date().getTime())
    }

    private loadAndPlayAllSounds() {
        this.loadSounds();

        Object.values(this.allSounds).forEach((sound) => {
            const canplaythrough = () => {
                sound.loaded = true;
            };
            sound.audio.addEventListener('canplaythrough', canplaythrough);
        });
    }

    private loadSounds() {
        Object.values(this.allSounds).forEach(({ audio, src }) => {
            audio.src = srcPath + src;
            audio.load();

            audio.addEventListener('ended', (el) => {
                if (!audio.ended) return;
                audio.volume = 1;
                audio.load();
            });
        });
    }
}
