import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Round } from '@app/models/round.model';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
    selector: 'pt-round-list',
    templateUrl: './round-list.component.html',
    styleUrls: ['./round-list.component.scss'],
})
export class RoundListComponent implements OnInit, AfterViewInit {
    @Input() scrollBar: NgScrollbar;
    @Input() rounds: Round[];
    @Input() currentRoundIndex: number;
    @Output() selectedRound = new EventEmitter<number>();

    constructor(private elRef: ElementRef<HTMLElement>) {}
    ngAfterViewInit(): void {
        this.scrollBar.scrollToElement('.current')
    }

    ngOnInit(): void {}
}
