import { Chip } from '@app/models/chip.model';

export interface DistributionErrors {
    notExactDist: { expected: number; returned: number };
    notEnoughChips: { expected: number; returned: number };
}

export const total = (chips: Chip | Chip[]): number => {
    if (Array.isArray(chips)) {
        return chips.reduce((_total, chip) => (_total += total(chip)), 0);
    } else {
        return chips.amount * chips.value;
    }
};

export const distribute = ({
    totalChips,
    targetValue,
    players,
}: {
    totalChips: Chip[];
    targetValue: number;
    players: number;
}): {
    chips: Chip[];
    errors: Partial<DistributionErrors>[];
    messages: string[];
} => {
    const errors: Partial<DistributionErrors>[] = [];

    const playerChips = totalChips.map((chip) => ({
        ...chip,
        amount: Math.floor(chip.amount / players),
    }));

    if (total(playerChips) < targetValue) {
        errors.push({
            notEnoughChips: {
                expected: targetValue,
                returned: total(playerChips),
            },
        });
        return { chips: playerChips, errors, messages: [] };
    }

    playerChips.sort((a, b) => b.value - a.value);

    for (const chip of playerChips) {
        if (total(playerChips) <= targetValue) {
            break;
        }

        if (total(playerChips) - total(chip) >= targetValue) {
            chip.amount = 0;
            continue;
        }

        const delta = total(playerChips) - targetValue;
        const chipsToReduce = Math.floor(delta / chip.value);
        chip.amount -= chipsToReduce;
    }
    if (total(playerChips) !== targetValue) {
        errors.push({
            notExactDist: { expected: targetValue, returned: total(playerChips) },
        });
    }

    return { chips: playerChips, errors, messages: [] };
};
