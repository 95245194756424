import { Injectable } from '@angular/core';
import {
    ErrorResponseData,
    ResponseEventData,
    SettingState,
} from '@app/models/backend-session.model';
import { Settings } from '@app/models/round.model';
import { TimerState } from '@app/models/timer.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const WEBSOCKET_URL = environment.webSocketUrl;

enum RequestEvents {
    'CREATE_GAME' = 'CREATE_GAME',
    'JOIN_GAME' = 'JOIN_GAME',
    'UPDATE_SETTINGS' = 'UPDATE_SETTINGS',
    'EXIT_GAME' = 'EXIT_GAME',
}

type RequestEvent = keyof typeof RequestEvents;

interface RequestPayload {
    event: RequestEvent | RequestEvents;
    data?: {
        code?: string;
        // eslint-disable-next-line
        master_code?: string;
        settings?: SettingState;
    };
}

export interface GameState {
    isConnected: boolean;
    isMaster?: boolean;
    code?: string;
    masterCode?: string;
}

export interface RecentSession {
    sessionId: string;
    masterCode?: string;
    lastUpdate: number;
    options: RecentSessionOptions;
}

export interface RecentSessionOptions {
    showControls: boolean;
}

const defaultRecentSessionOptions: RecentSessionOptions = {
    showControls: true,
};

const RESPONSE_TIMEOUT = 5000;

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    public sessionSettings = new BehaviorSubject<Settings>(null);
    public localSessionOptions = new BehaviorSubject<RecentSessionOptions>({
        ...defaultRecentSessionOptions,
    });
    public sessionTimerState = new BehaviorSubject<TimerState>(null);
    public sessionGameState = new BehaviorSubject<GameState>({ isConnected: false });

    private ws?: WebSocket;
    private onGameCreated = new Subject<ResponseEventData>();
    private onGameExited = new Subject<any>();
    private onGameUpdated = new Subject<ResponseEventData>();
    private onGameJoined = new Subject<ResponseEventData>();
    private onError = new Subject<ErrorResponseData>();

    constructor() {
        this.onGameUpdated.subscribe((data: ResponseEventData) =>
            this.handleGameSettingsUpdated(data.game.settings),
        );
        this.sessionGameState.subscribe((gameState: GameState) => {});
    }

    public async createGame(
        settings: Settings,
        timerState: TimerState,
    ): Promise<GameState> {
        return new Promise((resolve, reject) => {
            const gameDataSub = this.onGameCreated
                .pipe(
                    first((v) => !!v),
                    take(1),
                )
                .subscribe((gameData) => {
                    const gameState = {
                        isConnected: true,
                        code: gameData?.game.code,
                        masterCode: gameData?.game.master_code,
                        isMaster: true,
                    };
                    this.updateRecentSessions(gameState);
                    this.sessionGameState.next(gameState);
                    setTimeout(() => {
                        this.handleGameSettingsUpdated(gameData.game.settings)
                    }, 0);
                    resolve(this.sessionGameState.value);
                });

            setTimeout(() => {
                gameDataSub.unsubscribe();
                reject('New game request timed out');
            }, RESPONSE_TIMEOUT);

            this.wsSend({
                event: RequestEvents.CREATE_GAME,
                data: {
                    settings: {
                        settings: JSON.stringify(settings),
                        timerState: JSON.stringify(timerState),
                    },
                },
            });
        });
    }

    public joinGame(code: string, masterCode?: string): Promise<GameState> {
        console.log('try joinGame', { code, masterCode });
        return new Promise(async (resolve, reject) => {
            const onGameJoinedSub = this.onGameJoined
                .pipe(take(1))
                .subscribe(async (data) => {
                    console.log('onGameJoined emitted with', data);
                    const gameState = {
                        code,
                        masterCode,
                        isMaster: !!masterCode,
                        isConnected: true,
                    };
                    this.updateRecentSessions(gameState);
                    this.sessionGameState.next(gameState);
                    setTimeout(() => {
                        this.handleGameSettingsUpdated(data.game.settings)
                    }, 0);
                    resolve(gameState);
                });

            setTimeout(() => {
                onGameJoinedSub.unsubscribe();
                reject(
                    'Could not join game session with \ncode: ' +
                        code +
                        '\nmasterCode: ' +
                        masterCode,
                );
            }, RESPONSE_TIMEOUT);

            this.wsSend({
                event: 'JOIN_GAME',
                // eslint-disable-next-line
                data: { code, master_code: masterCode },
            });
        });
    }

    public async exitGame(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const onGameExitedSub = this.onGameExited
                .pipe(take(1))
                .subscribe(async (data) => {
                    this.sessionGameState.next({ isConnected: false });
                    this.closeWebsocket();
                    resolve();
                });

            setTimeout(() => {
                onGameExitedSub.unsubscribe();
                reject();
            }, RESPONSE_TIMEOUT);

            await this.wsSend({ event: 'EXIT_GAME' });
        });
    }

    public async updateGameSettings({
        settings,
        timerState,
    }: {
        settings?: Settings;
        timerState?: TimerState;
    }): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const onGameUpdated = this.onGameUpdated
                .pipe(take(1))
                .subscribe(async (data) => {
                    resolve();
                });

            setTimeout(() => {
                onGameUpdated.unsubscribe();
                reject();
            }, RESPONSE_TIMEOUT);

            const payload: RequestPayload = {
                event: RequestEvents.UPDATE_SETTINGS,
                data: { settings: {} },
            };

            if (settings != null) {
                payload.data.settings.settings = JSON.stringify(settings);
            }

            if (timerState != null) {
                payload.data.settings.timerState = JSON.stringify(timerState);
            }

            await this.wsSend(payload);
        });
    }

    public getRecentSession(sessionId: string): RecentSession {
        const sessions = this.getRecentSessions();
        return sessions.find((session) => session.sessionId === sessionId);
    }

    public updateRecentSessions(gameState?: GameState, options?: RecentSessionOptions) {
        const sessions = this.getRecentSessions();

        const now = Date.now();

        let localSessionOptions: RecentSessionOptions;
        if (gameState) {
            const savedSession = sessions.find(
                (session) => session.sessionId === gameState.code,
            );
            if (savedSession) {
                localSessionOptions = options ??
                    savedSession.options ?? { ...defaultRecentSessionOptions };

                savedSession.lastUpdate = now;
                savedSession.masterCode = gameState.masterCode ?? savedSession.masterCode;
                savedSession.options = localSessionOptions;
            } else {
                localSessionOptions = options ?? {
                    showControls: !!gameState.masterCode ?? true,
                };

                sessions.push({
                    lastUpdate: now,
                    sessionId: gameState.code,
                    masterCode: gameState.masterCode,
                    options: localSessionOptions,
                });
            }
        }

        if (localSessionOptions) {
            this.localSessionOptions.next(localSessionOptions);
        }
        
        // filter sessions older than 1 hour
        const filteredSessions = sessions.filter(
            (session, i) => session.lastUpdate + 3600000 > now,
        );

        localStorage.setItem('recentSessions', JSON.stringify(filteredSessions));
    }

    private getRecentSessions(): RecentSession[] {
        const recentSessionsJson = localStorage.getItem('recentSessions');
        return (JSON.parse(recentSessionsJson) as RecentSession[]) || [];
    }

    private async wsSend(request: RequestPayload): Promise<void> {
        await this.wsOpen();
        console.log('sending:', request);
        this.ws.send(JSON.stringify(request));
        return;
    }

    private wsOpen(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                return resolve();
            }

            this.ws = new WebSocket(WEBSOCKET_URL);

            this.ws.addEventListener('open', () => {
                console.log('Websocket opened.');
                resolve();
            });

            this.ws.addEventListener('close', () => {
                console.log('Websocket closed.');
                const gameState = this.sessionGameState.value
                if(gameState.isConnected){
                    console.log('Reconnecting to websocket.');
                    this.joinGame(gameState.code, gameState.masterCode)
                } else {
                    this.ws.removeAllListeners();
                }
                reject();
            });

            this.ws.addEventListener('error', (ev) => {
                console.error('Websocket error.', ev);
                reject();
            });

            this.ws.addEventListener('message', (payload: MessageEvent<string>) => {
                try {
                    const message = JSON.parse(payload.data);
                    console.log(
                        `Websocket message: ${message.event}\n${JSON.stringify(
                            message.data,
                            null,
                            2,
                        )}`,
                    );

                    switch (message.event) {
                        case 'GAME_CREATED':
                            this.onGameCreated.next(message.data);
                            break;
                        case 'GAME_EXITED':
                            this.onGameExited.next(message.data);
                            break;
                        case 'GAME_UPDATED':
                            this.onGameUpdated.next(message.data);
                            break;
                        case 'GAME_JOINED':
                            this.onGameJoined.next(message.data);
                            break;
                        case 'ERROR':
                            this.onError.next(message.data);
                            break;
                    }
                } catch {
                    console.error('Invalid websocket data received.');
                }
            });
        });
    }

    private closeWebsocket(): void {
        if (!this.ws) {
            return;
        }

        this.ws.close();
        this.ws.removeAllListeners();
        delete this.ws;
    }

    private handleGameSettingsUpdated(settings: SettingState): void {
        if (settings?.settings) {
            this.sessionSettings.next(JSON.parse(settings.settings));
        }
        if (settings?.timerState) {
            this.sessionTimerState.next(JSON.parse(settings.timerState));
        }
    }
}
