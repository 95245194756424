import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pt-timer-controls',
  templateUrl: './timer-controls.component.html',
  styleUrls: ['./timer-controls.component.scss']
})
export class TimerControlsComponent implements OnInit {

  @Input() isPlaying = false;
  @Input() disableBack = false;
  @Input() disableNext = false;

  @Output() onPlay = new EventEmitter<void>()
  @Output() onPause = new EventEmitter<void>()
  @Output() onNext = new EventEmitter<void>()
  @Output() onBack = new EventEmitter<void>()

  constructor() { }

  ngOnInit(): void {
  }

  keydown(event:KeyboardEvent){
    (event.target as HTMLElement).blur()
  }

}
