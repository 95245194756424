{{ !currentRound ? 'current round not loaded' : '' }}
<div
    class="sticky-wrapper"
    *ngIf="currentRound">
    <div class="top-bar">
        <button mat-icon-button *ngIf="controlsEnabled">
            <mat-icon
                [inline]="true"
                (click)="openPlaylistMenu()">
                playlist_play
            </mat-icon>
        </button>
        <button
            id="session-btn"
            [class.in-session]="inSession"
            mat-icon-button
            #sessionButton>
            <mat-icon
                [inline]="true"
                (click)="openSessionMenu()">
                devices
            </mat-icon>
        </button>
        <div class="flex-spacer"></div>
        <button
        *ngIf="controlsEnabled"
            mat-icon-button
            [routerLink]="['./settings']"
            queryParamsHandling="merge">
            <mat-icon [inline]="true">settings</mat-icon>
        </button>
    </div>
    <div class="main-div">
        <div class="progress-graphic">
            <pt-progress-graphic
                *ngIf="timerConfig"
                [padding]="'2 20'"
                [totalTime]="totalTime"
                [timeLeft]="timeLeft"
                [updatePeriod]="timerConfig.updatePeriod"
                [isPlaying]="isPlaying">
            </pt-progress-graphic>
        </div>
        <div class="grid">
            <div class="grid-top">
                <div
                    class="last-round"
                    [class.hide]="
                        (totalTime - timeLeft > minutesToMs(2) && !currentRound.break) || currentRoundIndex === 0
                    ">
                    <pt-simple-round-display
                        [title]="'Previous'"
                        [roundIndex]="currentRoundIndex - 1"
                        [allRounds]="allRounds"></pt-simple-round-display>
                </div>
                <div class="time-left">
                    <pt-timer [displayTime]="displayTime"></pt-timer>
                </div>
            </div>
            <div class="grid-middle">
                <div
                    class="blinds"
                    [class.enabled]="controlsEnabled"
                    [ngClass]="{ paused:  !isPlaying }"
                    (click)="controlsEnabled && toggleTimer()"
                    *ngIf="!currentRound.break; else break">
                    <div class="blind small">
                        <pt-blind
                            [amount]="currentRound.blinds.smallBlind"
                            textSide="left"
                            title="Small Blind"
                            [chipSettings]="(settings | async).chipsSettings"></pt-blind>
                    </div>
                    <div class="blind big">
                        <pt-blind
                            [amount]="currentRound.blinds.bigBlind"
                            textSide="right"
                            title="Big Blind"
                            [chipSettings]="(settings | async).chipsSettings"></pt-blind>
                    </div>
                </div>
                <ng-template #break>
                    <div
                        class="break"
                        [ngClass]="{ paused: !isPlaying }"
                        (click)="toggleTimer()">
                        <pt-break [breakMessage]="currentRound.breakMessage"></pt-break>
                        
                    </div>
                </ng-template>
                <div class="pause" [class.show]="!isPlaying" [class.paused]="isPaused" (click)="toggleTimer()">
                    <mat-icon [inline]="true" *ngIf="isPaused">pause</mat-icon>
                    <div class="start-round-message" *ngIf="!isPaused">
                        <p>Start Round</p>
                        <p class="hint">[space]</p>
                    </div>
                </div>
            </div>
            <div class="grid-bottom">
                <div class="next-round">
                    <pt-simple-round-display
                        [roundIndex]="currentRoundIndex + 1"
                        [allRounds]="allRounds"></pt-simple-round-display>
                </div>
                <div class="controls small-screen">
                    <ng-container [ngTemplateOutlet]="controls"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="controls large-screen">
        <ng-container [ngTemplateOutlet]="controls"></ng-container>
    </div>
    <div
        class="overlay"
        [class.active]="overlaysOpen">
        <div
            class="side-menu"
            id="playlist"
            [class.open]="playlistOpen">
            <div class="top-bar">
                <button
                    mat-icon-button
                    class="close-btn">
                    <mat-icon
                        [inline]="true"
                        (click)="closeAllOverlays()">
                        close
                    </mat-icon>
                </button>
            </div>
            <div
                class="content"
                *ngIf="playlistOpen"
                @fadeInOut>
                <ng-scrollbar
                    class="scrollbar"
                    #roundListScrollbar>
                    <pt-round-list
                        [scrollBar]="roundListScrollbar"
                        [rounds]="allRounds"
                        [currentRoundIndex]="currentRoundIndex"
                        (selectedRound)="onSelectRound($event)">
                    </pt-round-list>
                </ng-scrollbar>
            </div>
        </div>
        <div
            class="modal open caret"
            id="session"
            *ngIf="sessionMenuOpen"
            @fadeInOut
            [ptPositionBelow]="sessionButton._elementRef">
            <div class="top-bar">
                <button mat-icon-button>
                    <mat-icon
                        [inline]="true"
                        (click)="closeAllOverlays()">
                        close
                    </mat-icon>
                </button>
            </div>
            <div class="content">
                <pt-session-menu></pt-session-menu>
            </div>
        </div>
        <div
            class="backdrop"
            (click)="closeAllOverlays()">
        </div>
    </div>
</div>
<ng-template #controls>
    <pt-timer-controls
        *ngIf="controlsEnabled"
        [isPlaying]="isPlaying"
        (onPlay)="toggleTimer(true)"
        (onPause)="toggleTimer(false)"
        (onNext)="nextRound()"
        (onBack)="prevRound()"></pt-timer-controls>
</ng-template>
