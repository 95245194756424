import { Subject } from 'rxjs';

export interface TimerConfig {
    pausedTime: number;
    endTime: number;
    updatePeriod?: number;
    offsetTimerDisplay?: number;
}
export type TimerEventType = 'started' | 'stopped' | 'done' | 'notify' | 'reset';

export interface TimeComponents {
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
}

export interface TimerEventInterface {
    eventType: TimerEventType;
    timeMs: number;
    timeDisplay: TimeComponents;
    isRunning: boolean;
    endTime: number;
}
const defaultConfig: TimerConfig = {
    endTime: null,
    pausedTime: 0,
    offsetTimerDisplay: 999,
    updatePeriod: 200,
};
const MS_PER_HOUR = 3600000;
const MS_PER_MINUTE = 60000;
const MS_PER_SECOND = 1000;

const now = () => new Date().getTime();
export const endTimeFromMs = (ms: number) => now() + ms;

export const getTimeResponse = (timeMs: number): TimeComponents => {
    const hours = Math.floor(timeMs / MS_PER_HOUR);
    let remainder = timeMs - hours * MS_PER_HOUR;

    const minutes = Math.floor(remainder / MS_PER_MINUTE);
    remainder -= minutes * MS_PER_MINUTE;

    const seconds = Math.floor(remainder / MS_PER_SECOND);
    remainder -= seconds * MS_PER_SECOND;

    return { hours, minutes, seconds, milliseconds: remainder };
};

export class Timer {
    public update = new Subject<TimerEventInterface>();

    get remainingTime(): number {
        const { endTime, pausedTime, updatePeriod } = this;
        const timeLeft = endTime ? Math.max(endTime - now(), 0) : pausedTime;
        return Math.round(timeLeft / updatePeriod) * updatePeriod;
    }

    private config: TimerConfig;
    private endTime: number;
    private pausedTime: number;
    private intervalHandle = setTimeout(() => {}, 0);
    private updatePeriod: number;

    // tslint:disable-next-line:variable-name

    private get isRunning(): boolean {
        return this.endTime !== null;
    }

    constructor(config: TimerConfig = defaultConfig) {
        this.setConfig(config);
    }

    setConfig(config: TimerConfig) {
        this.config = {
            ...defaultConfig,
            ...config,
        };
        this.reset();
    }

    start(): void {
        console.log('start()');

        this.endTime = this.remainingTime + now();
        this.pausedTime = null;

        this.fireEvent('started');

        this.intervalHandle = setInterval(() => this.checkTime(), this.updatePeriod);
    }

    stop(): void {
        console.log('stop()');

        if (!this.isRunning) {
            return;
        }

        this.pausedTime = this.endTime - now();
        this.endTime = null;

        clearInterval(this.intervalHandle);
        this.fireEvent('stopped');
    }

    reset(): void {
        
        if (this.endTime) {
            this.pausedTime = this.endTime - now();
            this.endTime = null;
        }

        clearInterval(this.intervalHandle);
        this.resetConfig();
        this.fireEvent('reset');
        
    }

    private resetConfig(): void {
        const { pausedTime, endTime, updatePeriod }: TimerConfig = {
            ...this.config,
        };
        this.endTime = endTime;
        this.pausedTime = endTime ? null : pausedTime;
        this.updatePeriod = updatePeriod;
        this.checkTime();
    }

    private checkTime(): void {
        if (!this.isRunning) {
            return;
        }

        if (this.remainingTime > 0) {
            this.fireEvent('notify');
            return;
        }

        this.pausedTime = 0;
        this.endTime = null;

        clearInterval(this.intervalHandle);
        this.fireEvent('done');
    }

    private fireEvent(eventType: TimerEventType): void {
        const e = {
            eventType,
            timeMs: this.remainingTime,
            timeDisplay: getTimeResponse(
                this.remainingTime + (this.config.offsetTimerDisplay ?? 0),
            ),
            isRunning: this.isRunning,
            endTime: this.endTime,
        }
        this.update.next(e);
    }
}
