<button
  mat-icon-button
  color="white"
  (keypress)="keydown($event)"
  [disabled]="this.disableBack"
  (click)="onBack.emit()">
  <mat-icon [inline]="true">skip_previous</mat-icon>
</button>
<button
  class="middle-button"
  mat-icon-button
  color="white"
  *ngIf="isPlaying === true"
  (keypress)="keydown($event)"
  (click)="onPause.emit()">
  <mat-icon [inline]="true">pause</mat-icon>
</button>
<button
  class="middle-button"
  mat-icon-button
  color="white"
  *ngIf="isPlaying === false"
  (keypress)="keydown($event)"
  (click)="onPlay.emit()">
  <mat-icon [inline]="true">play_arrow</mat-icon>
</button>
<button
  mat-icon-button
  color="white"
  (keypress)="keydown($event)"
  [disabled]="this.disableNext"
  (click)="onNext.emit()">
  <mat-icon [inline]="true">skip_next</mat-icon>
</button>
