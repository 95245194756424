import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from '@app/models/round.model';
import { SessionService } from '@app/services/session.service';
import { SettingsService } from '@app/services/settings.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { combineLatest, Subscription } from 'rxjs';
import { first, tap, withLatestFrom } from 'rxjs/operators';
import { createChipFormGroup } from './chips-settings/chips-settings.component';
import { createControlGroupFromRound } from './round-settings/round-settings.component';

type ViewsId = keyof { general; rounds; chips };

@Component({
    selector: 'pt-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
    @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

    settings: Settings;

    menuOpen = false;
    displaying: ViewsId = 'rounds';

    settingsForm: FormGroup;

    subs$ = new Subscription();

    views: { id: ViewsId; title: string }[] = [
        {
            id: 'rounds',
            title: 'Rounds',
        },
        {
            id: 'chips',
            title: 'Chips',
        },
        {
            id: 'general',
            title: 'General Settings',
        },
    ];

    constructor(
        private settingsService: SettingsService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private session: SessionService,
    ) {}
    ngOnInit(): void {
        // TODO: check session on load instead of redirecting
        this.route.url
            .pipe(
                first(),
                tap(() => console.log('Received update')),
                withLatestFrom(this.session.sessionGameState),
            )
            .subscribe(([url, sessionState]) => {
                const urlArray = url.map((segment) => segment.path.toLowerCase());
                if (urlArray.includes('session') && !sessionState.isConnected) {
                    this.router.navigate(['../'], { relativeTo: this.route });
                }
            });
        const settingsSub = this.settingsService.settings.subscribe((settings) => {
            this.settings = settings;
            if(!this.settingsForm){
                this.createForm();
            }
            this.patchSettingsInForm(settings);
        });
        this.subs$.add(settingsSub);
        this.displaying = 'chips' ?? this.views[0].id;
    }

    ngOnDestroy(): void {
        this.subs$.unsubscribe();
    }

    display(id: ViewsId) {
        this.displaying = id;
        this.menuOpen = false;
        setTimeout(() => {
            this.scrollbarRef.update();
        }, 0);
    }

    createForm() {
        this.settingsForm = this.fb.group({
            general: this.fb.group({
                playSounds: true,
            }),
            roundSettings: this.fb.group({
                defaultBreakTime: [0, Validators.required],
                defaultRoundTime: [0, Validators.required],
                rounds: this.fb.array([]),
            }),
            chipsSettings: this.fb.group({
                chips: this.fb.array([]),
                players: 10,
                chipValuePerPlayer: 10000,
                showChipsWithRounds: true,
            }),
        });
    }

    patchSettingsInForm(settings: Settings) {
        this.settingsForm.patchValue(settings);

        // Patch rounds in FormArray as controls
        const roundsArray = this.settingsForm.get('roundSettings.rounds') as FormArray;
        roundsArray.setValue([])
        settings?.roundSettings?.rounds?.forEach((round) => {
            roundsArray.push(createControlGroupFromRound(round));
        });
        // Patch chips in FormArray as controls
        const chipsArray = this.settingsForm.get('chipsSettings.chips') as FormArray;
        chipsArray.setValue([])
        settings?.chipsSettings?.chips?.forEach((chip) => {
            chipsArray.push(createChipFormGroup(chip));
        });

        console.log(this.settingsForm);
    }

    save() {
        this.settingsForm.updateValueAndValidity();
        console.log('form valid?: ', this.settingsForm.valid, this.settingsForm.errors);
        if (this.settingsForm.touched && this.settingsForm.valid) {
            const formValues = this.settingsForm.value as Settings;
            formValues.roundSettings.rounds = formValues.roundSettings.rounds.filter(
                (r) => !!r.id,
            );
            this.settingsService.saveSettings(formValues);
            this.settingsForm.markAsPristine();
        } else {
            /* TODO: emit toastr error */
            console.log(this.settingsForm.value, this.settingsForm.errors);
            this.settingsForm.markAllAsTouched();
        }
    }
}
