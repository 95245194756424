import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameState, SessionService } from '@app/services/session.service';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { debounce, debounceTime, filter, map, take, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'pt-session-wrapper',
    templateUrl: './session-wrapper.component.html',
    styleUrls: ['./session-wrapper.component.scss'],
})
export class SessionWrapperComponent implements OnInit, OnDestroy {
    isMaster: boolean;
    isConnected: boolean;
    showControls: Observable<boolean> = this.sessionService.localSessionOptions
        .asObservable()
        .pipe(map((options) => options.showControls));
    private subs = new Subscription();

    constructor(
        private activeRoute: ActivatedRoute,
        private sessionService: SessionService,
        private router: Router,
        private location: Location,
    ) {}

    ngOnDestroy(): void {
        this.subs?.unsubscribe();
    }

    ngOnInit(): void {

        const routeSub = combineLatest([
            this.activeRoute.url,
            this.activeRoute.queryParams,
            this.activeRoute.params
        ]).pipe(withLatestFrom(this.sessionService.sessionGameState)).subscribe(([[url, queryParams, params], session]) => {
            const SSGameState = this.getSavedGameState();
            const urlId = params?.id;
            const urlMasterCode = queryParams?.controller;
            const recentSession = this.sessionService.getRecentSession(urlId);

            // if there is no session id, return to main
            if (!urlId) {
                this.alert({ error: 'Could not connect. No session ID provided' });
                this.router.navigate(['/']);
                return;
            }

            // [SESSION ID IS AVAILABLE IN URL]

            // if game is not connected
            if (!session?.isConnected) {
                // if there is master code in url
                if (urlMasterCode ?? false) {
                    // try connecting with master code in url
                    this.connect(urlId, urlMasterCode)
                        .then(() => {
                            this.cleanUrlFromMasterCode(urlId);
                        })
                        .catch(() => {
                            this.alert({ error: 'The game could not be found' });
                            this.router.navigate(['/']);
                        });
                    return;
                }

                // [THERE IS NO MASTER CODE IN URL]

                // if there is session storage game state && ids are the same && master code is present
                if (
                    SSGameState?.code === urlId &&
                    SSGameState.isMaster &&
                    SSGameState.masterCode
                ) {
                    // try connection with master code and id
                    this.connect(urlId, SSGameState.masterCode).catch(() => {
                        this.alert({ error: 'The game could not be found' });
                        this.router.navigate(['/']);
                    });
                    return;
                }

                // check if recent sessions were stored with master code
                if (recentSession && recentSession.masterCode) {
                    this.connect(urlId, recentSession.masterCode).catch(() => {
                        this.alert({ error: 'The game could not be found' });
                        this.router.navigate(['/']);
                    });
                    return;
                }

                // [THERE IS NO MASTER CODE]
                // connect with url id only
                this.connect(urlId);
                return;
            }

            // [GAME IS CONNECTED]

            // if url id is different than session code,
            if (urlId !== session.code) {
                const masterCode = urlMasterCode ?? recentSession?.masterCode;
                // connect to a new session with URL id and master code if there is one
                this.connect(urlId, masterCode)
                    .then(() => {
                        if (urlMasterCode ?? false) {
                            this.cleanUrlFromMasterCode(urlId);
                        }
                    })
                    .catch(() => {
                        this.alert({ error: 'The game could not be found' });
                        this.router.navigate(['/']);
                    });
                return;
            }

            // [ID IS SAME AS SESSION ID]

            // if master code is in URL
            if (urlMasterCode) {
                // if session is not master
                if (!session.isMaster) {
                    // connect to a new session with master code
                    this.connect(urlId, urlMasterCode)
                        .then(() => {
                            this.cleanUrlFromMasterCode(urlId);
                        })
                        .catch(() => {
                            this.alert({ error: 'The game could not be found' });
                            this.router.navigate(['/']);
                        });
                    return;
                }
                this.cleanUrlFromMasterCode(urlId);
            }

            // [MASTER CODE IS NOT IN URL]
            this.isConnected = true;
            this.isMaster = session.isMaster;
            return;
        });

        this.subs.add(routeSub);
    }

    connect(sessionId: string, masterCode?: string): Promise<void> {
        return this.sessionService
            .joinGame(sessionId, masterCode)
            .then((gameState: GameState) => {
                this.saveGameState(gameState);

                if (!gameState?.isConnected) {
                    this.alert({ error: 'Could not connect to session' });
                    this.router.navigate(['/']);
                    return;
                }
                this.alert({ success: 'Session started' });
                this.isConnected = true;
                this.isMaster = gameState.isMaster;

                if ((masterCode ?? false) && !this.isMaster) {
                    this.alert({
                        error: 'Controller code was incorrect. Session started in spectator mode.',
                    });
                }

                return;
            });
    }

    alert({ warn, error, success }: { warn?: string; error?: string; success?: string }) {
        if (warn) console.warn('Warning: ' + warn);
        if (error) console.error('Error: ' + warn);
        if (success) console.log('Success: ' + warn);
    }

    getSavedGameState(): GameState {
        return JSON.parse(sessionStorage.getItem('gameState'));
    }

    saveGameState(gameState: GameState) {
        sessionStorage.setItem('gameState', JSON.stringify(gameState));
    }

    cleanUrlFromMasterCode(sessionId: string) {
        this.location.replaceState('/session/' + sessionId);
    }
}
