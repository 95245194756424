import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainScreenComponent } from "@app/main-screen/main-screen.component";
import { SessionWrapperComponent } from "@app/session-wrapper/session-wrapper.component";
import { SettingsComponent } from "@app/settings/settings.component";
import { AppComponent } from "./app.component";

const routes: Routes = [
    {path: "", pathMatch: "full", component: MainScreenComponent, data: {disableScrollbar: true}},
    {path: "settings", pathMatch: "full", component: SettingsComponent, data: {animation: 'Overlay'}},
    {path: "session/:id", pathMatch: "full", component: SessionWrapperComponent, data: {disableScrollbar: true}},
    {path: "session/:id/settings", pathMatch: "full", component: SettingsComponent, data: {animation: 'Overlay'}},
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }