export interface TimerState {pausedTime:number, endTime: number, currentRound: number}
export class TimerState {
    constructor(timerState?: Partial<TimerState>){
        this.currentRound = 0;
        this.endTime = null;
        this.pausedTime = 0;
        if(timerState){
            Object.assign(this,timerState)
        }
    }
}