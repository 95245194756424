import { Chip } from "@app/models/chip.model";

export const defaultChips2: Chip[] = [
    {value: 5, color: "#f7f7f7",  amount: 100},
    {value: 25, color: "#c11010",  amount: 100},
    {value: 100, color: "#1111bb",  amount: 100},
    {value: 500, color: "#198f19",  amount: 100},
    {value: 1000, color: "#1d1b1b",  amount: 100},
  ]

  export const defaultChips: Chip[] = [
    {value: 25, color: "#f7f7f7", amount: 100},
    {value: 50, color: "#c11010", amount: 100},
    {value: 100, color: "#1111bb", amount: 100},
    {value: 500, color: "#198f19", amount: 100},
    {value: 1000, color: "#1d1b1b", amount: 100},
  ]