<h1>Chips Settings</h1>
<div style="margin-bottom: 20px;">
  <button
    mat-raised-button
    color="primary"
    (click)="addChip()">
    Add Chip
  </button>
  <button
    mat-stroked-button
    color="white"
    (click)="sort()">
    Sort
  </button>
</div>
<ng-container [formGroup]="chipsForm">
  <div
    formArrayName="chips"
    class="chips-container">
    <div
      class="chip-form-group"
      *ngFor="let chip of chipsFormArrayControls, let i = index"
      [formGroup]="chip">
      <button
        (click)="removeAt(i)"
        mat-icon-button
        class="remove">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <pt-chip
        [color]="chip.value.color"
        [value]="chip.value.value"
        size="100%"></pt-chip>
      <div class="tooltip-input-group">
        <div class="form-group">
          <label for="color">&nbsp;</label>
          <input
            class="color"
            type="color"
            formControlName="color">
        </div>
        <div class="form-group">
          <label for="value">Value</label>
          <input
            class="value"
            type="number"
            formControlName="value"
            placeholder="Value"
            min="0"
            max="999999999">
        </div>
        <div class="form-group">
          <label for="amount">Quantity</label>
          <input
            class="amount"
            type="number"
            formControlName="amount"
            placeholder="amount"
            min="0"
            max="999999999">
        </div>
      </div>
    </div>







  </div>
  <h2>Distribution Assistant</h2>
  <div>
    <div class="form-group">
      <label for="players">Number of players</label>
      <input
        type="number"
        formControlName="players">
    </div>
    <div class="form-group">
      <label for="chipValuePerPlayer">Chip value per player</label>
      <p>
        Maximum value per player is {{maxPerPlayer | number: '1.0-2'}}
      </p>
      <input
        type="number"
        formControlName="chipValuePerPlayer"
        [max]="maxPerPlayer">
    </div>
    <button
      *ngIf="!distribution"
      mat-stroked-button
      color="white"
      (click)="distributeChips()">
      Generate distribution
    </button>
  </div>
</ng-container>
<div
  class="messages"
  *ngIf="distribution">
  <ng-container *ngIf="distribution.errors?.length; else noErrors">
    <p *ngFor="let error of distribution.errors">
      <span *ngIf="error.notEnoughChips">
        There weren't enough chip to distribute {{error.notEnoughChips.expected | number: '1.0-2'}} to each player. Could only distribute{{error.notEnoughChips.returned  | number: '1.0-2'}}.
      </span>
      <span *ngIf="error.notExactDist">
        It wasn't possible to distribute exactly {{error.notExactDist.expected | number: '1.0-2'}} to each player. The closest possible is {{error.notExactDist.returned  | number: '1.0-2'}}.
      </span>


    </p>
  </ng-container>
  <ng-template #noErrors>
    <p>
      Perfect distribution! Each player gets {{totalDistribution  | number: '1.0-2'}}
    </p>
  </ng-template>
</div>
<div
  class="distribution"
  *ngIf="distribution">
  <div
    class="chip-col"
    *ngFor="let chip of distribution.chips">
    <div class="chip-stack">
      <div
        class="chip-container"
        *ngFor="let instance of chip.amount | repeat">
        <pt-chip
          size="100%"
          [showValue]="true"
          [color]="chip.color"
          [value]="chip.value"></pt-chip>
      </div>
    </div>
    <div class="chip-info">×{{chip.amount}}</div>
  </div>
</div>
