import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Chip } from '@app/models/chip.model';
import { ChipsSettings } from '@app/models/round.model';

interface ChipDisplay {
  repeat: number;
  chip: Chip;
}

@Component({
  selector: 'pt-blind',
  templateUrl: './blind.component.html',
  styleUrls: ['./blind.component.scss'],
})
export class BlindComponent implements OnInit, OnChanges {
  @HostBinding('class.expand-left') get expandLeft(){
    return this.textSide === 'left'
  }
  @HostBinding('class.expand-right') get expandRight(){
    return this.textSide === 'right'
  }
  @Input() title = 'Blind';
  @Input() amount = 150;
  @Input() chipSettings: ChipsSettings;
  @Input() textSide: 'right' | 'left' = 'left';

  chipsArray: ChipDisplay[] = [];
  maxRepeatChip = 5; // max allowed repeat chips

  get maxTotalChips(): number {
    return Math.max(this.chipSettings?.chips?.length, 5)
  }

  get totalChips(): number {
    return this.chipsArray.reduce((total, chip) => (total += chip.repeat), 0);
  }

  showMultiplierForAll(): boolean {
    const chipsDisplayed = this.chipsArray.reduce((total, chip) => {
      total += chip.repeat > this.maxRepeatChip ? 1 : chip.repeat;
      return total
    },0)
    return chipsDisplayed > this.maxTotalChips
  }

  showMultiplier(chipDisplay:ChipDisplay): boolean{
    return chipDisplay.repeat > this.maxRepeatChip || this.showMultiplierForAll()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title || changes.amount || changes.chipSettings) {
      this.chipsArray = this.getChipArray(this.amount, this.chipSettings.chips);
    }
  }

  ngOnInit(): void {
    if (this.chipSettings?.chips) {
      this.chipsArray = this.getChipArray(this.amount, this.chipSettings.chips);
    }
  }

  getChipArray(blind: number, chips: Chip[]): ChipDisplay[] {
    const returnChips: ChipDisplay[] = [];
    const allChips = [...chips].sort((a, b) => a.value - b.value > 0 ? -1 : 1);
    allChips.forEach((chip) => {
      if (blind < chip.value) return;

      let q = Math.floor(blind / chip.value);
      returnChips.push({
        chip,
        repeat: q,
      });
      blind -= chip.value * q;
      for (q; q > 0; q--) {}
    });
    return returnChips;
  }
}
