import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pt-break',
  templateUrl: './break.component.html',
  styleUrls: ['./break.component.scss']
})
export class BreakComponent implements OnInit {
  @Input() breakMessage: string;
  

  ngOnInit(): void {
    /** not empty you f*ing linter */
  }

}
