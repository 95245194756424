import { Component, Input, OnInit } from '@angular/core';
import { Round } from '@app/models/round.model';

@Component({
  selector: 'pt-simple-round-display',
  templateUrl: './simple-round-display.component.html',
  styleUrls: ['./simple-round-display.component.scss']
})
export class SimpleRoundDisplayComponent implements OnInit {
  @Input() title = "Next";
  @Input() roundIndex: number;
  @Input() allRounds: Round[];

  get nextRound():Round{
    return this.allRounds[this.roundIndex]
  }
  constructor() { }

  ngOnInit(): void {
  }

}
