import { Component, OnInit } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: 'pt-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  get generalSettings() {
    return this.controlContainer.control as FormGroup;
}
  constructor(public controlContainer: ControlContainer) { }

  ngOnInit(): void {
    
  }

}
