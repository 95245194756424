import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[ptPositionBelow]',
})
export class PositionBelowDirective implements OnInit {
    @Input() ptPositionBelow: ElementRef<HTMLElement>;

    constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

    ngOnInit(): void {
        const inputElRect = this.ptPositionBelow.nativeElement.getBoundingClientRect();
        this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'absolute');
        this.elementRef.nativeElement.style.setProperty('--modal-top-offset', inputElRect.bottom + 'px')
        this.elementRef.nativeElement.style.setProperty('--modal-pointer-left-offset', inputElRect.right - (inputElRect.width / 2) - 15 + 'px')
    }
}
