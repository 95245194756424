import { Round } from "../models/round.model";
import { v4 as uuid } from 'uuid';

export const defaultRounds2: Round[] = [
  { id: uuid(), time: 10, blinds: { smallBlind: 5, bigBlind: 10 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 10, bigBlind: 20 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 15, bigBlind: 30 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 5s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 25, bigBlind: 50 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 50, bigBlind: 100 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 75, bigBlind: 150 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 25s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 100, bigBlind: 200 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 200, bigBlind: 400 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 300, bigBlind: 600 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 400, bigBlind: 800 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 100s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 500, bigBlind: 1000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 1000, bigBlind: 2000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 1500, bigBlind: 3000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 2000, bigBlind: 4000 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 500s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 3000, bigBlind: 6000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 4000, bigBlind: 8000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 5000, bigBlind: 10000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 6000, bigBlind: 12000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 8000, bigBlind: 16000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 10000, bigBlind: 20000 } },
]

export const defaultRounds: Round[] = [
  { id: uuid(), time: 10, blinds: { smallBlind: 25, bigBlind: 50 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 50, bigBlind: 100 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 75, bigBlind: 150 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 25s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 100, bigBlind: 200 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 150, bigBlind: 300 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 200, bigBlind: 400 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 50s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 300, bigBlind: 600 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 400, bigBlind: 800 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 500, bigBlind: 1000 } },
  { id: uuid(), time: 5, break: true },
  { id: uuid(), time: 10, blinds: { smallBlind: 600, bigBlind: 1200 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 800, bigBlind: 1600 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 1000, bigBlind: 2000 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 100s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 1500, bigBlind: 3000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 2000, bigBlind: 4000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 2500, bigBlind: 5000 } },
  { id: uuid(), time: 5, break: true, breakMessage: 'Remove 500s' },
  { id: uuid(), time: 10, blinds: { smallBlind: 3000, bigBlind: 6000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 4000, bigBlind: 8000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 5000, bigBlind: 10000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 6000, bigBlind: 12000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 8000, bigBlind: 16000 } },
  { id: uuid(), time: 10, blinds: { smallBlind: 10000, bigBlind: 20000 } },
]