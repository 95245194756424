import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TimerState } from '@app/models/timer.model';
import { GameState, RecentSession, SessionService } from '@app/services/session.service';
import { SettingsService } from '@app/services/settings.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'pt-session-menu',
    templateUrl: './session-menu.component.html',
    styleUrls: ['./session-menu.component.scss'],
})
export class SessionMenuComponent implements OnInit, OnDestroy {
    isConnected = false;
    isMaster = false;
    sessionId;
    controllerCode;

    maskControllerCode = true;

    viewerUrl: string;
    controllerUrl: string;

    sub: Subscription = new Subscription();

    showControls = new FormControl(true);

    private gameState: GameState;

    constructor(
        private sessionService: SessionService,
        private settingsService: SettingsService,
        private router: Router,
    ) {
        const sessionSub = this.sessionService.sessionGameState.subscribe(
            (gameState: GameState) => {
                this.gameState = gameState;
                this.isConnected = gameState.isConnected;
                this.isMaster = gameState.isMaster;
                this.sessionId = gameState.code;
                this.controllerCode = gameState.masterCode;

                const recentSession = this.sessionService.getRecentSession(
                    this.sessionId,
                );
                this.setSessionOptions(recentSession);
                this.updateLinks();
            },
        );
        this.sub.add(sessionSub);
    }
    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {}
    startNewSession() {
        this.sessionService
            .createGame(
                this.settingsService.settings.value,
                new TimerState(),
            )
            .then((gameState: GameState) => {
                console.log('Returned new session');
                this.redirectToSession(gameState.code, gameState.masterCode);
                this.updateLinks();
            });
    }
    connectToSession() {
        this.redirectToSession(this.sessionId, this.controllerCode);
    }
    endSession() {
        // one day we would end sessions
        this.disconnect();
    }

    disconnect() {
        this.router.navigate(['/']);
        this.sessionService.exitGame();
        this.settingsService.loadSettingsAndTimerState();
    }

    private redirectToSession(sessionId: string, masterCode: string): void {
        const options = masterCode
            ? {
                  queryParams: {
                      controller: masterCode,
                  },
              }
            : {};
        this.router.navigate(['session', sessionId], options);
    }

    private updateLinks() {
        this.viewerUrl = this.makeViewerUrl();
        if (this.isMaster) {
            this.controllerUrl = this.makeControllerUrl();
        }
    }
    private makeControllerUrl() {
        return this.makeViewerUrl() + `?controller=${this.controllerCode}`;
    }
    private makeViewerUrl() {
        const baseUrl = environment.baseUrl;
        return `${baseUrl}/session/${this.sessionId}`;
    }

    private setSessionOptions(recentSession: RecentSession) {
        if (this.isMaster) {
            this.showControls.setValue(recentSession.options.showControls);
            const controlSub = this.showControls.valueChanges.subscribe(
                (val: boolean) => {
                    this.sessionService.updateRecentSessions(this.gameState, {
                        showControls: val,
                    });
                },
            );

            this.sub.add(controlSub);
        }
    }
}
