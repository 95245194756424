<div class="blind-text">
    <div class="title">{{title}}</div>
    <div class="blind-amount">{{amount | highNumber}}</div>
</div>
<div
    class="chips-container"
    *ngIf="chipSettings?.showChipsWithRounds">
    <ng-container *ngFor="let chipDisplay of chipsArray">
        <div
            class="chip"
            [class.has-multiplier]="showMultiplier(chipDisplay)"
            *ngFor="let chip of [].constructor((showMultiplier(chipDisplay)) ? 1 : chipDisplay.repeat)">
            <div
                class="repeat-text"
                [class.right]="textSide === 'right'"
                *ngIf="showMultiplier(chipDisplay)">
                {{ textSide === 'right' ? 'x' : ''}} {{chipDisplay.repeat}} {{ textSide === 'left' ? 'x' : ''}}
            </div>
            <pt-chip
                size="100%"
                [showValue]="false"
                [color]="chipDisplay.chip.color"
                [value]="chipDisplay.chip.value"></pt-chip>
        </div>
    </ng-container>

</div>
