import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInOut } from '@app/core/animation.triggers';
import { Round, Settings } from '@app/models/round.model';
import { TimerState } from '@app/models/timer.model';
import { SessionService } from '@app/services/session.service';
import { SettingsService } from '@app/services/settings.service';
import { SoundsService } from '@app/services/sounds.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import {
    endTimeFromMs,
    getTimeResponse,
    TimeComponents,
    Timer,
    TimerConfig,
    TimerEventInterface,
} from './timer/timer.model';
import { Location } from '@angular/common';

const statesDifferent = (stateA, stateB): boolean =>
    JSON.stringify(stateA) !== JSON.stringify(stateB);

const baseConfig: TimerConfig = {
    pausedTime: 10000,
    endTime: null,
    updatePeriod: 200,
};
const minutesToMs = (minutes: number): number => (minutes ?? 0) * 60 * 1000;

@Component({
    selector: 'pt-main-screen',
    templateUrl: './main-screen.component.html',
    styleUrls: ['./main-screen.component.scss'],
    animations: [fadeInOut],
})
export class MainScreenComponent implements OnInit, OnDestroy {
    @Input() controlsEnabled = true;
    @Input() inSession = false;

    settings = this.settingsService.settings;
    timeLeft: number;
    totalTime: number;
    isPlaying = false;
    currentRoundIndex: number;
    currentRound: Round;
    minutesToMs = minutesToMs;

    overlaysOpen = false;
    sessionMenuOpen = false;
    playlistOpen = false;

    timer = new Timer();
    displayTime: TimeComponents = getTimeResponse(0);

    saveInterval = setInterval(() => {}, 0);
    public timerConfig: TimerConfig = baseConfig;

    private countdownNotifications = [1000, 2000, 3000];
    private subs: Subscription[] = [];
    private storedTimerState: TimerState;

    get isPaused(): boolean {
        return !this.isPlaying && this.timeLeft !== this.totalTime;
    }

    get allRounds(): Round[] {
        return this.settingsService.rounds.value ?? [];
    }

    constructor(
        private settingsService: SettingsService,
        private soundsService: SoundsService,
        private activeRoute: ActivatedRoute,
        private sessionService: SessionService,
        private router: Router,
        private location: Location,
    ) {}

    @HostListener('document:keyup', ['$event'])
    keyboardEvent(event: KeyboardEvent){
      if(event.key === ' '){
        this.toggleTimer()
      }
    }

    ngOnInit(): void {
        this.setRound(0);

        const timerSub = this.timer.update.subscribe((e) => this.timerUpdateEvent(e));
        const timerStateSub = this.settingsService.timerState
            .pipe(filter((v) => !!v))
            .subscribe((timerState) => {
                this.setTimerState(timerState);
            });

        this.subs.push(timerSub, timerStateSub);
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }

    timerUpdateEvent(e: TimerEventInterface) {
        this.displayTime = e.timeDisplay;
        this.timeLeft = e.timeMs;
        if (e.eventType === 'done') {
            this.nextRound();
            this.soundsService.playNewRoundSound();
            return;
        }
        if (e.eventType === 'reset') {
            if (this.isPlaying) {
                this.timer.start();
            }
            return;
        }
        if (e.eventType === 'notify') {
            if (this.countdownNotifications.includes(e.timeMs)) {
                this.soundsService.playSecondsWarning();
            } else if (e.timeMs === minutesToMs(1)) {
                this.soundsService.playMinuteWarning();
            }
            return;
        }
        if (e.eventType === 'started') {
            this.timerConfig.endTime = e.endTime;
            // this.saveState();
        }
        if (e.eventType === 'stopped') {
            this.timerConfig.endTime = null;
            // this.saveState();
        }
    }

    setTimerState(timerState: TimerState) {
        const currentRoundIndex = timerState.currentRound ?? this.currentRoundIndex ?? 0;
        this.currentRound = this.allRounds[currentRoundIndex];
        this.currentRoundIndex = currentRoundIndex;
        this.totalTime = minutesToMs(this.currentRound?.time);
        this.timerConfig = {
            ...baseConfig,
            endTime: timerState.endTime,
            pausedTime: timerState.pausedTime ? Math.min(timerState.pausedTime, this.totalTime) : this.totalTime,
        };
        this.isPlaying = !!timerState?.endTime;
        this.timer.setConfig(this.timerConfig);
    }

    setRound(roundIndex: number) {
        this.currentRound = this.allRounds[roundIndex];
        this.currentRoundIndex = roundIndex;
        this.totalTime = minutesToMs(this.currentRound?.time);
        this.timerConfig = {
            ...baseConfig,
            endTime: this.isPlaying ? endTimeFromMs(this.totalTime) : null,
            pausedTime: this.isPlaying ? null : this.totalTime,
        };
        this.timer.setConfig(this.timerConfig);
    }

   

    toggleTimer(play?: boolean) {
        if (!this.controlsEnabled) return;

        this.isPlaying = play ?? !this.isPlaying;
        if (this.isPlaying) {
            this.timer.start();
            this.saveState();

            // this.saveInterval = setInterval(this.saveState.bind(this), 1000);
        } else {
            this.timer.stop();
            this.saveState();

            // clearInterval(this.saveInterval);
        }
    }

    nextRound() {
        if (this.currentRoundIndex + 1 < this.allRounds.length) {
            this.setRound(this.currentRoundIndex + 1);
        }
        this.saveState();
    }
    prevRound() {
        if (this.totalTime - this.timeLeft > 1000) {
            this.setRound(this.currentRoundIndex);
        } else if (this.currentRoundIndex - 1 >= 0) {
            this.setRound(this.currentRoundIndex - 1);
        }
        this.saveState();
    }

    saveState() {
        this.settingsService.saveTimerState({
            pausedTime: this.isPlaying ? null : this.timer.remainingTime,
            endTime: this.isPlaying ? this.timerConfig.endTime : null,
            currentRound: this.currentRoundIndex,
        });
    }

    onSelectRound(i: number) {
        this.setRound(i);
        this.saveState();
        setTimeout(() => {
            this.closeAllOverlays();
        }, 100);
    }

    public openPlaylistMenu() {
        this.closeAllOverlays();
        this.playlistOpen = true;
        this.overlaysOpen = true;
    }

    public openSessionMenu() {
        this.closeAllOverlays();
        this.sessionMenuOpen = true;
        this.overlaysOpen = true;
    }

    public closeAllOverlays() {
        this.overlaysOpen = false;
        this.playlistOpen = false;
        this.sessionMenuOpen = false;
    }
}
