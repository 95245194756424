import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'highNumber'})
export class HighNumberPipe implements PipeTransform {


  transform(value: number | string): string {
    if(typeof value === "number"){
      if(value < 1000 || value % 100 !== 0) return ""+value
      if(value < 10 ** 6 || value % 1000 !== 0) return (value / 1000)+"K"
      if(value < 10 ** 9) return (value / 10**6)+"M"
    } else {
      return value
    }
  }
}