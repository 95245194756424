<button
    *ngFor="let round of rounds; let i = index"
    class="round"
    (click)="selectedRound.emit(i)"
    [class.current]="i === currentRoundIndex"
    [class.past]="i < currentRoundIndex"
    [class.upcoming]="i > currentRoundIndex">
    <p
        *ngIf="!round.break && round.blinds"
        class="blinds">
        {{round.blinds.smallBlind | highNumber}} / {{round.blinds.bigBlind | highNumber}}
    </p>
    <p
        *ngIf="round.break"
        class="break">
        Break
    </p>
    <p class="time">{{round.time * 60000 | date:'mm:ss'}}</p>
</button>
