<div class="chip" 
    [class.contrast]="isLight" 
    [style.color]="color" 
    [style.width]="size">

  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 376.1 242.72" xml:space="preserve" width="100%" height="100%">
      <path class="main-fill" d="M188.04 0C139.91 0 91.78 10.61 55.07 31.81 18.35 53.01 0 80.78 0 108.56s18.34 55.58 55.06 76.78c73.44 42.4 192.5 42.4 265.94 0 36.72-21.2 55.06-48.97 55.06-76.75S357.72 53.01 321 31.81C284.29 10.61 236.16 0 188.04 0z" />
      <path class="main-fill" d="M0 108.56c0 27.78 18.34 55.58 55.06 76.78 73.44 42.4 192.5 42.4 265.94 0 36.72-21.2 55.06-48.97 55.06-76.75l.03 25.57c0 27.78-18.36 55.57-55.08 76.77-69.78 40.29-180.76 42.29-254.65 6.01-3.87-1.9-7.63-3.9-11.29-6.01C18.36 189.74 0 161.95 0 134.17v-25.61z" />
      <path class="white-lines" d="M205.77 215.99v25.5c.23.49.77.62 1.42.59 32.12-1.5 60.34-9.55 60.34-9.55.63-.1.96-.57.73-1.06v-25.5" />
      <path class="white-lines" d="M80.12 197.47c-15.14-6.21-29.84-13.86-42.76-23.97-2.05-.88-2.44-2.52-.27-3.17 7.33-4.23 14.65-8.46 21.98-12.69 3.37-1.72 5.37 3.19 8.2 4.4 11.76 8.7 25 15.26 38.61 20.52.67 1.26-1.63 1.67-2.44 2.37-7.12 4.12-14.24 8.25-21.37 12.37-.58.32-1.33.47-1.95.17zm-44.5-25.57l.23 26.06 1.83 1.42c12.92 10.11 27.29 17.88 42.43 24.09v-26" />
      <path class="white-lines" d="M358.14 153.5v26c.85.13 1.32-.07 1.6-.41 13.8-16.81 15.63-35.39 15.63-35.39l.18-25.3" />
      <path class="shadow" d="M0 108.56c0 27.78 18.34 55.58 55.06 76.78 73.44 42.4 192.5 42.4 265.94 0 36.72-21.2 55.06-48.97 55.06-76.75l.03 25.57c0 27.78-18.36 55.57-55.08 76.77-69.78 40.29-180.76 42.29-254.65 6.01-3.87-1.9-7.63-3.9-11.29-6.01C18.36 189.74 0 161.95 0 134.17v-25.61z" />
      <path class="main-fill" d="M282.64 163.2c-52.24 30.17-136.95 30.18-189.21.01S41.16 84.14 93.4 53.97c.01-.01.02-.01.03-.02 52.24-30.17 136.95-30.18 189.21-.01s52.27 79.07.03 109.24c-.01.01-.02.01-.03.02z" />
      <path class="shadow" d="M282.64 163.2c-52.24 30.17-136.95 30.18-189.21.01S41.16 84.14 93.4 53.97c.01-.01.02-.01.03-.02 52.24-30.17 136.95-30.18 189.21-.01s52.27 79.07.03 109.24c-.01.01-.02.01-.03.02z" />
      <path class="white-lines" d="M375.58 118.14s-3.64 18.55-17.44 35.36c-.28.34.85.13 0 0l-31.79-4.92c-.85-.13-2.13-.61-1.82-.94 14.6-16.12 15.8-34.92 15.8-34.92.17-.37.99-.55 1.84-.42l32.18 4.95c.85.12 1.4.52 1.23.89z" />
      <path class="white-lines" d="M267.53 207.03s-28.22 8.05-60.34 9.55c-.65.03-1.19-.1-1.42-.59l-8.52-18.35c-.23-.49-.15-1.37.5-1.38 31.48-.76 60.29-9.56 60.29-9.56.63-.1 1.32.22 1.55.71l8.67 18.57c.23.48-.1.95-.73 1.05z" />
      <path class="white-lines" d="M80.12 197.47s-1.64-.63-4.38-1.82c-2.74-1.18-6.59-2.92-11-5.14-4.42-2.22-9.41-4.91-14.44-8.01-5.03-3.1-10.1-6.6-14.68-10.43-.37-.31-.44-.65.18-1.01l23.27-13.44c.62-.36 1.97-.76 2.33-.44 16.88 15.36 44.49 25.36 44.49 25.36.46.27.33.77-.29 1.13L82.08 197.3c-.63.36-1.5.44-1.96.17z" />
      <path class="white-lines" d="M.72 98.98s2.04-18.14 15.84-34.95c.28-.34.75-.54 1.6-.41l31.79 4.92c.85.13 2.13.61 1.82.94-14.6 16.12-15.8 34.92-15.8 34.92-.17.37-.99.55-1.84.42L1.95 99.88c-.86-.13-1.4-.53-1.23-.9z" />
      <path class="white-lines" d="M108.76 10.03S136.98 1.98 169.1.48c.65-.03 1.19.1 1.42.59l8.52 18.35c.23.49.15 1.37-.5 1.38-31.48.76-60.29 9.56-60.29 9.56-.63.1-1.32-.22-1.55-.71l-8.67-18.57c-.23-.48.09-.95.73-1.05z" />
      <path class="white-lines" d="M296.2 19.58s26.18 10.09 44.5 25.39c.37.31.44.65-.18 1.01l-23.27 13.44c-.62.36-1.97.76-2.33.44-16.88-15.36-44.49-25.36-44.49-25.36-.46-.27-.33-.77.29-1.13l23.51-13.61c.63-.37 1.5-.45 1.97-.18z" />
      <path class="white-lines" d="M64.42 137.73s-7.33-10.48-8.7-18.75c-.05-.32.43-.66 1.29-.79l4.07-.63c.86-.13 1.75 0 1.8.32 1.92 10.77 8.7 18.75 8.7 18.75.14.31-.43.66-1.29.79l-4.07.63c-.86.13-1.66-.01-1.8-.32z" />
      <path class="white-lines" d="M55.72 98.09s2.73-11.2 8.7-18.75c.23-.29.95-.45 1.8-.32l4.07.63c.86.13 1.51.5 1.29.79-7.67 9.88-8.7 18.75-8.7 18.75-.14.31-.95.45-1.8.32L57 98.88c-.85-.13-1.43-.48-1.28-.79z" />
      <path class="white-lines" d="M82.51 61.24s12.06-8.91 23.77-13.73c.45-.18 1.21-.12 1.84.25l2.98 1.72c.63.36.87.87.43 1.06-15.19 6.34-23.77 13.73-23.77 13.73-.39.23-1.21.12-1.84-.25l-2.98-1.72c-.63-.36-.82-.83-.43-1.06z" />
      <path class="white-lines" d="M238.78 179.98s-18.16 4.23-32.47 5.02c-.55.03-1.15-.25-1.38-.74l-1.09-2.35c-.23-.49 0-1.01.55-1.04 18.65-1.11 32.47-5.02 32.47-5.02.53-.08 1.15.25 1.38.74l1.09 2.35c.22.49-.02.96-.55 1.04z" />
      <path class="white-lines" d="M170.12 185s-19.39-1.58-32.47-5.02c-.5-.13-.78-.55-.55-1.04l1.09-2.35c.23-.49.87-.87 1.38-.74 17.11 4.43 32.47 5.02 32.47 5.02.53.08.78.55.55 1.04l-1.09 2.35c-.24.49-.85.82-1.38.74z" />
      <path class="white-lines" d="M106.3 169.53s-15.43-6.96-23.77-13.73c-.32-.26-.2-.7.43-1.06l2.98-1.72c.63-.36 1.51-.5 1.84-.25 10.98 8.77 23.77 13.73 23.77 13.73.39.23.2.7-.43 1.06l-2.98 1.72c-.63.37-1.45.48-1.84.25z" />
      <path class="white-lines" d="M311.95 79.31s7.33 10.48 8.7 18.75c.05.32-.43.66-1.29.79l-4.07.63c-.86.13-1.75 0-1.8-.32-1.92-10.77-8.7-18.75-8.7-18.75-.14-.31.43-.66 1.29-.79l4.07-.63c.85-.13 1.65.01 1.8.32z" />
      <path class="white-lines" d="M320.66 118.95s-2.73 11.2-8.7 18.75c-.23.29-.95.45-1.8.32l-4.07-.63c-.86-.13-1.51-.5-1.29-.79 7.67-9.88 8.7-18.75 8.7-18.75.14-.31.95-.45 1.8-.32l4.07.63c.85.13 1.43.48 1.29.79z" />
      <path class="white-lines" d="M293.86 155.8s-12.06 8.91-23.77 13.72c-.45.18-1.21.12-1.84-.25l-2.98-1.72c-.63-.36-.87-.87-.43-1.06 15.19-6.34 23.77-13.72 23.77-13.72.39-.23 1.21-.12 1.84.25l2.98 1.72c.63.36.82.83.43 1.06z" />
      <path class="white-lines" d="M137.6 37.06s18.16-4.23 32.47-5.02c.55-.03 1.15.25 1.38.74l1.09 2.35c.23.49 0 1.01-.55 1.04-18.65 1.11-32.47 5.02-32.47 5.02-.53.08-1.15-.25-1.38-.74l-1.09-2.35c-.23-.49.01-.96.55-1.04z" />
      <path class="white-lines" d="M206.26 32.04s19.39 1.58 32.48 5.02c.5.13.78.55.55 1.04l-1.09 2.35c-.23.49-.87.87-1.38.74-17.11-4.43-32.48-5.02-32.48-5.02-.53-.08-.78-.55-.55-1.04l1.09-2.35c.23-.49.84-.82 1.38-.74z" />
      <path class="white-lines" d="M270.08 47.51s15.43 6.96 23.77 13.73c.32.26.2.7-.43 1.06l-2.98 1.72c-.63.36-1.51.5-1.84.25-10.98-8.77-23.77-13.73-23.77-13.73-.39-.23-.2-.7.43-1.06l2.98-1.72c.63-.37 1.45-.48 1.84-.25z" />
      <g transform="translate(14.023 510.83)">
        <a>
          <path class="main-fill" d="M263.38-350.65c-49.35 28.5-129.38 28.51-178.74.01-49.37-28.49-49.38-74.7-.03-103.2.01-.01.02-.01.03-.01 49.35-28.5 129.38-28.51 178.74-.01 49.37 28.49 49.38 74.7.03 103.2-.01 0-.02.01-.03.01z" />
       </a>
      </g>
  </svg>
  <svg version="1.1"  class="text-svg" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 376.1 242.72" xml:space="preserve" width="100%" height="100%">
    <text class="text" [style.--text-color]="darkerColor" x="50%" y="40%" dominant-baseline="middle" text-anchor="middle">
      {{ (showValue ? value : '' )| highNumber}}
    </text>
  </svg>
</div>