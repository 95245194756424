import { Component, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import {
    BaseControlValueAccessor,
    makeValueAccessor,
} from '@app/models/value-accessor-base';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pt-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    providers: [makeValueAccessor(SwitchComponent)],
})
export class SwitchComponent
    extends BaseControlValueAccessor<boolean>
    implements ControlValueAccessor
{
    @ViewChild('switchEl') switchElement: ElementRef<HTMLInputElement>;

    constructor() {
        super();
    }

    change($event: Event) {
        const val = ($event.target as HTMLInputElement).checked as boolean;
        this.onChange(val);
    }
}
