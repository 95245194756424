<div class="settings-wrapper">
    <div class="top-bar">
        <button
            class="mobile-only"
            mat-button
            (click)="menuOpen = !menuOpen">
            <mat-icon [inline]="true">menu</mat-icon>
        </button>
        <div class="flex-spacer"></div>
        <button
            mat-button
            (click)="save()"
            [disabled]="!settingsForm.dirty">
            Save
        </button>
        <button
            [color]="settingsForm.dirty ? 'accent' : 'white'"
            mat-button
            [routerLink]="['../']"
            queryParamsHandling="merge">
            {{settingsForm.dirty ? 'Discard' : 'Close'}}
            <mat-icon [inline]="true">close</mat-icon>
        </button>
    </div>
    <main>
        <ng-scrollbar class="scrollbar">
            <div class="menu-layout">
                <div
                    class="menu"
                    [class.open]="menuOpen">
                    <div class="top-bar mobile-only">
                        <button
                            mat-icon-button
                            (click)="menuOpen = false">
                            <mat-icon [inline]="true">close</mat-icon>
                        </button>
                    </div>
                    <div class="menu-items">
                        <button
                            *ngFor="let view of views"
                            [class.active]="displaying === view.id"
                            (click)="display(view.id)">
                            {{view.title}}
                        </button>
                    </div>
                </div>
                <div
                    class="backdrop mobile-only"
                    *ngIf="menuOpen"
                    (click)="menuOpen = !menuOpen"></div>
                <div
                    class="content"
                    [formGroup]="settingsForm">
                    <pt-round-settings
                        *ngIf="settings && displaying === 'rounds' "
                        formGroupName="roundSettings">
                    </pt-round-settings>
                    <pt-chips-settings
                        *ngIf="settings && displaying === 'chips'"
                        formGroupName="chipsSettings">
                    </pt-chips-settings>
                    <pt-general-settings
                        *ngIf="settings && displaying === 'general'"
                        formGroupName="general">
                    </pt-general-settings>
                </div>
            </div>
        </ng-scrollbar>
    </main>
</div>
