import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainScreenComponent } from './main-screen.component';
import { ProgressGraphicComponent } from './progress-graphic/progress-graphic.component';
import { BlindComponent } from './blind/blind.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from '@app/core/app-routing.module';
import { CountdownModule } from 'ngx-countdown';
import { TimerComponent } from './timer/timer.component';
import { TimerControlsComponent } from './timer-controls/timer-controls.component';
import { BreakComponent } from './break/break.component';
import { SimpleRoundDisplayComponent } from './next-round/simple-round-display.component';
import { RoundListComponent } from './round-list/round-list.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SessionMenuComponent } from './session-menu/session-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ClipboardModule} from '@angular/cdk/clipboard';


@NgModule({
  declarations: [
    MainScreenComponent,
    ProgressGraphicComponent,
    BlindComponent,
    TimerComponent,
    TimerControlsComponent,
    BreakComponent,
    SimpleRoundDisplayComponent,
    RoundListComponent,
    SessionMenuComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    MatIconModule,
    MatButtonModule,
    AppRoutingModule,
    CountdownModule,
    NgScrollbarModule
  ],
  exports: [
    MainScreenComponent
  ]
})
export class MainScreenModule { }
