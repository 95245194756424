import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time',
})
export class TimePipe implements PipeTransform {
    transform(value: number): string {
        if (value > 0 && value < 60) {
            return value + 'Minutes';
        }
        
        if (value >= 60) {
            const min = value % 60;
            return Math.floor(value / 60) + ' Hours ' + (min > 0 ? min + ' Minutes' : '');
        }

        return '0 Minutes';
    }
}
