import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { basicChipColours } from '@app/defaults/basic-chip-colours';
import { Chip } from '@app/models/chip.model';
import { ChipsSettings } from '@app/models/round.model';
import { distribute, DistributionErrors, total } from '@app/services/chip-distribution.factory';
import * as Color from 'color-js';
import { detectColor } from 'detect-color';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

export const createChipFormGroup = (chip: Chip): FormGroup =>
    new FormGroup({
        value: new FormControl(chip.value, Validators.required),
        amount: new FormControl(chip.amount, Validators.required),
        color: new FormControl(Color(chip.color).toCSS(), Validators.required),
    });

@Component({
    selector: 'pt-chips-settings',
    templateUrl: './chips-settings.component.html',
    styleUrls: ['./chips-settings.component.scss'],
})
export class ChipsSettingsComponent implements OnInit, OnDestroy {
    distribution: { chips: Chip[]; errors: Partial<DistributionErrors>[]; messages: string[]; };
    get chipsForm() {
        return this.controlContainer.control as FormGroup;
    }

    get chipsFormArray() {
        return this.chipsForm.get('chips') as FormArray;
    }
    get chipsFormArrayControls() {
        return this.chipsFormArray.controls as FormGroup[];
    }
    get nextUnusedColour(): string {
        const usedChips = this.chipsFormArray.value as Chip[];
        const usedColorNames = [];
        usedChips.forEach((chip) => {
            usedColorNames.push(detectColor(chip.color).detectedColor);
        });
        for (const color of basicChipColours) {
            if (!usedColorNames.includes(color.name)) {
                return color.hex;
            }
        }
        return basicChipColours.find((c) => c.name === 'default').hex;
    }

    maxPerPlayer: number;
    subs = new Subscription();
    totalDistribution: number;
    constructor(public controlContainer: ControlContainer, public fb: FormBuilder) {}
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.sort();

        this.chipsForm
            .get('chipValuePerPlayer')
            .addValidators(this.validateMaxChipValue.bind(this));
        const formValueSub = this.chipsForm.valueChanges
            .pipe(startWith(this.chipsForm.value))
            .subscribe((value) => {
                this.maxPerPlayer = total(value.chips as Chip[]) / value.players;
                this.distribution = null;
            });
        this.subs.add(formValueSub);
    }

    removeAt(i: number) {
        this.chipsFormArray.removeAt(i);
        this.chipsForm.markAsDirty();
    }

    addChip() {
        this.chipsFormArray.insert(
            0,
            createChipFormGroup({
                color: this.nextUnusedColour,
                value: 0,
                amount: 0,
            }),
        );
        this.chipsForm.markAsDirty();
    }

    sort() {
        this.chipsFormArrayControls.sort((a, b) =>
            a.value.value < b.value.value ? -1 : 1,
        );
    }

    validateMaxChipValue(
        control: AbstractControl,
    ): null | { [e: string]: boolean | string } {
        return this.maxPerPlayer && control.value <= this.maxPerPlayer
            ? null
            : { max: true };
    }

    distributeChips() {
        const {
            chips: totalChips,
            players,
            chipValuePerPlayer: targetValue,
        } = this.chipsForm.value as ChipsSettings;
        const distribution = distribute({ totalChips, players, targetValue });
        distribution.chips = distribution.chips.filter(chip => chip.amount > 0)
        this.distribution = distribution;
        this.totalDistribution = total(distribution.chips)
    }
}
