<label
    class="toggle"
    [class.disabled]="disabled">
    <input
        [checked]="value"
        (input)="change($event)"
        type="checkbox"
        #switchEl>
    <span class="slider"></span>
    <span
        class="labels"
        data-on="ON"
        data-off="OFF"></span>
</label>
<ng-content></ng-content>
