import { Component, Input, OnInit } from '@angular/core';
import * as Color from 'color-js'

@Component({
  selector: 'pt-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() size = "200px";
  @Input() value:number|string = 5;
  @Input() showValue = true;
  @Input() set color(color: string){
    this._color = color;
    this.isLight = Color(this.color).getLightness() > 0.7;
  };
  get color(){ return this._color }

  isLight = false;

  private _color = 'blue';

  get darkerColor(){
    const amount = 0.15 //this.isLight ? 0.1 : 0.06;
    const color = Color(this.color)[(this.isLight ? 'darken' : 'lighten')+'ByAmount'](amount).toString()

    return color
  }
  
  constructor() { 
    this.color = this._color
  }

  ngOnInit(): void {
  }

}
