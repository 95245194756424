<div [formGroup]="formGroup">
    <h1>Round Settings</h1>
    <p>Total game time: {{totalGameTime | time}}</p>
    <div class="settings">
        <div class="form-group">
            <label>Default Round Time:</label>
            <div class="input-group">
                <input
                    type="number"
                    formControlName="defaultRoundTime"
                    (click)="$event.target['select']()">
                <button
                    mat-flat-button
                    color="primary"
                    (click)="applyDefaultRound()">
                    Apply to all rounds
                </button>
            </div>
        </div>
        <div class="form-group">
            <label>Default Break Time</label>
            <div class="input-group">
                <input
                    type="number"
                    formControlName="defaultBreakTime"
                    (click)="$event.target['select']()">
                <button
                    mat-flat-button
                    color="primary"
                    (click)="applyDefaultBreak()">
                    Apply to all breaks
                </button>
            </div>
        </div>
    </div>


    <div class="rounds-row header">
        <div class="rounds-cell icon drag-handle"></div>
        <div class="rounds-cell time">Minutes</div>
        <div class="rounds-cell small">Small Blind</div>
        <div class="rounds-cell big">Big Blind</div>
        <div class="rounds-cell icon"></div>
    </div>
    <div
        @preventChildAnimations
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        class="rounds"
        formArrayName="rounds">
        <div
            cdkDrag
            class="rounds-row"
            *ngFor="let round of formRoundControls; let i = index;"
            [cdkDragDisabled]="!round.value.id"
            [class.break]="round.get('break')?.value === true"
            @roundsAnimation
            [formGroup]="round">
            <div
                class="rounds-cell icon drag-handle"
                cdkDragHandle>
                <mat-icon
                    [inline]="true"
                    *ngIf="round.value.id">
                    drag_indicator
                </mat-icon>
            </div>
            <div
                class="rounds-cell time"
                *ngIf="round.value.id">
                <input
                    #timeInput
                    type="number"
                    formControlName="time"
                    (click)="focusAndSelect($event)"
                    placeholder="Min">
            </div>
            <ng-container *ngIf="round.value.id && round.get('break')?.value === false;">
                <ng-container
                    formGroupName="blinds"
                    *ngIf="round.get('blinds')">
                    <div class="rounds-cell small">
                        <input
                            type="number"
                            placeholder="Small"
                            formControlName="smallBlind"
                            (click)="focusAndSelect($event)"
                            (change)="onChangeSmallBlind(round)">
                    </div>
                    <div class="rounds-cell big">
                        <input
                            type="number"
                            placeholder="Big"
                            formControlName="bigBlind"
                            (click)="focusAndSelect($event)"
                            (keydown.Tab)="tabOnBigBlind(i)">
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="round.value.id && round.get('break')?.value === true;">
                <div class="rounds-cell break-message">
                    <input
                        type="string"
                        formControlName="breakMessage"
                        placeholder="Break message?">
                </div>
            </ng-container>
            <ng-container *ngIf="!round.value.id">
                <div class="rounds-cell round-type-buttons">
                    <div class="buttons">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="addBlindRound(i)">
                            Add Round
                        </button>
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="addBreakRound(i)">
                            Add Break
                        </button>
                    </div>
                </div>
            </ng-container>
            <div
                class="rounds-cell button"
                *ngIf="round.value.id">
                <button
                    mat-icon-button
                    tabindex="-1"
                    [matMenuTriggerFor]="more">
                    <mat-icon [inline]="true">more_horiz</mat-icon>
                </button>
                <mat-menu #more="matMenu">
                    <button
                        mat-menu-item
                        color="error"
                        (click)="removeRound(i)">
                        <mat-icon>remove_circle_outline</mat-icon>
                        <span>Remove</span>
                    </button>
                    <button
                        mat-menu-item
                        (click)="addRoundPlaceholder(i)">
                        <mat-icon>add_circle_outline</mat-icon>
                        <span>Add round before</span>
                    </button>
                    <button
                        mat-menu-item
                        (click)="addRoundPlaceholder(i+1)">
                        <mat-icon>add_circle_outline</mat-icon>
                        <span>Add round after</span>
                    </button>
                </mat-menu>
            </div>
            <div
                class="rounds-cell button"
                *ngIf="!round.value.id">
                <button
                    mat-icon-button
                    color="error"
                    (click)="removeRound(i)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <p>Total: {{totalGameTime | time}}</p>
    <div class="buttons">
        <button
            mat-flat-button
            color="primary"
            (click)="addBlindRound()">
            Add Round
        </button>
        <button
            mat-flat-button
            color="primary"
            (click)="addBreakRound()">
            Add Break
        </button>
    </div>
</div>
<ng-template #loading>Loading settings...</ng-template>
