import { defaultChips } from "@app/defaults/default-chips";
import { defaultRounds } from "../defaults/default-rounds";
import { Chip } from "./chip.model";

export class Settings {
  general: GeneralSettings = {playSounds: true}
  roundSettings: RoundSettings = {
    defaultBreakTime: 5,
    defaultRoundTime: 10,
    rounds: [...defaultRounds]
  }
  chipsSettings: ChipsSettings = {
    chips: [...defaultChips],
    players: 10,
    chipValuePerPlayer: 10000,
    showChipsWithRounds: true
  }

  constructor(settings?: any){
    if(settings){
      // backwards compatibility
      if(!settings.general && settings.playSounds){
        this.general.playSounds = settings.playSounds
      } else {
        this.general = settings.general
      }
      if(!settings.roundSettings){
        this.roundSettings = {
          defaultBreakTime: settings.defaultBreakTime ?? this.roundSettings.defaultBreakTime,
          defaultRoundTime: settings.defaultRoundTime ?? this.roundSettings.defaultRoundTime,
          rounds: settings.rounds ?? this.roundSettings.rounds,
        }
      } else {
        this.roundSettings = settings.roundSettings
      }
      if(settings.chipsSettings){
        this.chipsSettings = {...this.chipsSettings, ...settings.chipsSettings}
      }
    }
  }
}

export interface GeneralSettings {
  playSounds: boolean
}

export interface RoundSettings {
    rounds: Round[],
    defaultBreakTime: number,
    defaultRoundTime: number,
}

export interface ChipsSettings {
  chips: Chip[],
  players: number,
  chipValuePerPlayer: number,
  showChipsWithRounds: boolean,
}

export interface Round {
  id: string,
  time: number,
  blinds?: {
    smallBlind: number,
    bigBlind: number
  },
  break?: boolean,
  breakMessage?: string,
}
