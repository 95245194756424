import { forwardRef, Provider, Type } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export class BaseControlValueAccessor<T> implements ControlValueAccessor {
    public disabled = false;

    public value: T;
    public onChange(newVal: T) {}
    public onTouched(_?: any) {}

    public writeValue(obj: T): void {
        this.value = obj;
    }
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

export const makeValueAccessor = (T: Type<any>):Provider => ({
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => T),
    multi: true,
});
