<svg
    [style.transition]="animate && isPlaying ? 'stroke-dashoffset '+updatePeriod+'ms linear' : 'none'"
    #mainSvg
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    preserveAspectRatio="none">
    <!-- The paths are calculated dynamically based on container dimensions -->
    <path
        #basePath
        #path
        class="base"
        d
        fill="none"
        stroke-width="4"
        [attr.stroke-dasharray]="length"
        [attr.stroke-dashoffset]="length + (length - (length * progress))"></path>
    <path
        #progressPath
        #path
        class="progress"
        d
        fill="none"
        stroke-width="5"
        [attr.stroke-dasharray]="length"
        [attr.stroke-dashoffset]="length - (length * progress)"></path>
</svg>
