import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsSettingsComponent } from './chips-settings/chips-settings.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { RoundSettingsComponent } from './round-settings/round-settings.component';
import { SettingsComponent } from './settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatMenuModule } from '@angular/material/menu';
import { AppRoutingModule } from '@app/core/app-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [
        RoundSettingsComponent,
        SettingsComponent,
        ChipsSettingsComponent,
        GeneralSettingsComponent,
    ],
    imports: [
        CommonModule,
        MatCheckboxModule,
        AppRoutingModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedComponentsModule,
        DragDropModule,
        MatMenuModule,
        NgScrollbarModule,
    ],
    exports: [
      SettingsComponent
    ]
})
export class SettingsModule {}
