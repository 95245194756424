import { Component, Input, OnInit } from '@angular/core';
import { getTimeResponse, TimeComponents } from './timer.model';

@Component({
    selector: 'pt-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
    @Input() displayTime: TimeComponents = getTimeResponse(0);

    constructor() {}

    ngOnInit(): void {}
}
