<ng-container *ngIf="!isConnected; else isConnectedTemplate">
    <div>
        <h2>Start a Session</h2>
        <p>
            Sync a single game session across multiple devices
        </p>
        <button
            mat-raised-button
            color="primary"
            class="wide large"
            (click)="startNewSession()">
            Start a new session
        </button>
    </div>
    <hr>
    <div>
        <h2>Connect to a Session</h2>
        <div class="form-group">
            <input
                type="number"
                name="sessionId"
                id="sessionId"
                class="large"
                [(ngModel)]="sessionId"
                placeholder="Session ID"
                inputmode="decimal">
        </div>
        <div class="form-group">
            <input
                type="number"
                name="controllerCode"
                id="controllerCode"
                class="large"
                [(ngModel)]="controllerCode"
                placeholder="Controller code"
                inputmode="decimal">
        </div>
        <button
            mat-flat-button
            color="primary"
            (click)="connectToSession()"
            class="wide large">
            Connect
        </button>
    </div>
</ng-container>
<ng-template #isConnectedTemplate>
    <div>
        <h2>Session in progress</h2>
        <div class="form-group">
            <label for="sessionId">Session ID</label>
            <div class="input-group">
                <input
                    [(ngModel)]="sessionId"
                    type="number"
                    name="sessionId"
                    id="sessionId"
                    placeholder="Session ID"
                    inputmode="decimal"
                    class="large"
                    readonly>
                <button
                    mat-icon-button
                    [cdkCopyToClipboard]="viewerUrl"
                    color="primary">
                    <mat-icon [inline]="true">link</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [cdkCopyToClipboard]="sessionId"
                    color="primary">
                    <mat-icon [inline]="true">content_copy</mat-icon>
                </button>
            </div>
        </div>
        <div
            class="form-group"
            *ngIf="isMaster">
            <label for="controllerCode">Controller Code</label>
            <div class="input-group">
                <input
                    *ngIf="!maskControllerCode"
                    [(ngModel)]="controllerCode"
                    type="number"
                    name="controllerCode"
                    id="controllerCode"
                    placeholder="Controller code"
                    inputmode="decimal"
                    class="large"
                    readonly>
                <input
                    type="text"
                    *ngIf="maskControllerCode"
                    readonly
                    value=" • • • • • •">
                <button
                    mat-icon-button
                    (click)="maskControllerCode = !maskControllerCode"
                    color="primary">
                    <mat-icon [inline]="true">
                        {{ !maskControllerCode ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </button>
                <button
                    mat-icon-button
                    [cdkCopyToClipboard]="controllerUrl"
                    color="primary">
                    <mat-icon [inline]="true">link</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [cdkCopyToClipboard]="controllerCode"
                    color="primary">
                    <mat-icon [inline]="true">content_copy</mat-icon>
                </button>
            </div>
        </div>
        <div class="show-controls" *ngIf="isMaster">
            <pt-switch [formControl]="showControls">Show controls on this screen</pt-switch>
        </div>
    </div>
    <hr>
    <div *ngIf="!isMaster">
        <div class="form-group">
            <label for="controllerCode">Enable Control</label>
            <div class="input-group">
                <input
                    type="number"
                    name="controllerCode"
                    id="controllerCode"
                    class="large"
                    [(ngModel)]="controllerCode"
                    placeholder="Controller code"
                    inputmode="decimal">
                <button
                    mat-flat-button
                    color="primary"
                    (click)="connectToSession()"
                    class="large">
                    Connect
                </button>
            </div>
        </div>
        <hr>
    </div>
    
    <div>
        <button
            *ngIf="isMaster"
            mat-stroked-button
            color="warn"
            (click)="endSession()"
            class="wide large">
            End Game
        </button>
        <button
            mat-stroked-button
            color="accent"
            (click)="disconnect()"
            class="wide large">
            Leave Session
        </button>
    </div>
</ng-template>
