import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { delay, filter, map, take } from 'rxjs/operators';
import { Round, Settings } from '../models/round.model';
import { TimerState } from '../models/timer.model';
import { SessionService } from './session.service';

const settingsVersion = '3.1';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    timerState = new BehaviorSubject<TimerState>(null);
    rounds = new BehaviorSubject<Round[]>([]);
    settings = new BehaviorSubject<Settings>(null);

    private isSessionOpen: boolean;
    get storage(): Storage {
        return this.isSessionOpen ? sessionStorage : localStorage;
    }

    constructor(private sessionService: SessionService) {
        this.settings.pipe(filter((s) => !!s)).subscribe((s) => {
            this.rounds.next(s.roundSettings.rounds);
        });


        this.sessionService.sessionSettings.subscribe((settings) => {
            if(this.isSessionOpen){
                this.saveLocalSettings(settings)
            }
        });

        this.sessionService.sessionTimerState.subscribe((timerState) => {
            if(this.isSessionOpen){
                this.saveLocalTimerState(timerState)
            }
        });

        this.sessionService.sessionGameState.subscribe((state) => {
            if(this.isSessionOpen !== state?.isConnected){
                this.isSessionOpen = state?.isConnected;
                this.loadSettingsAndTimerState()
            }
        });
        
        this.loadSettingsAndTimerState()
    }

    loadSettingsAndTimerState(){
        const settings = this.getSettings()
        this.settings.next(new Settings(settings));
        const timerState = this.getTimerState()
        this.timerState.next(timerState);
    }
    getSettings(): Settings {
        const json = this.storage.getItem('settings-' + settingsVersion);
        return json ? JSON.parse(json) : null;
    }

    saveSettings(settings: Settings){
        this.saveLocalSettings(settings)
        this.saveRemoteSettings(settings)
    }

    saveLocalSettings(settings: Settings){
        this.settings.next(settings)
        const json = JSON.stringify(settings);
        this.storage.setItem('settings-' + settingsVersion, json);

    }

    saveRemoteSettings(settings: Settings) {
        if (this.isSessionOpen && this.sessionService.sessionGameState.value.isMaster) {
            this.sessionService.updateGameSettings({ settings });
        }
    }
    
    saveTimerState(timerState: TimerState) {
        this.saveLocalTimerState(timerState)
        this.saveRemoteTimerState(timerState)
    }

    saveLocalTimerState(timerState: TimerState){
        this.timerState.next(timerState)
        const json = JSON.stringify(timerState);
        this.storage.setItem('timerState-' + settingsVersion, json);

    }
    saveRemoteTimerState(timerState: TimerState){
        if (this.isSessionOpen && this.sessionService.sessionGameState.value.isMaster) {
            this.sessionService.updateGameSettings({ timerState });
        }
    }


    private getTimerState(): TimerState {
        const json = this.storage.getItem('timerState-' + settingsVersion);
        return json ? JSON.parse(json) : null;
    }
}
